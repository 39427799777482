import type { ControllerParams } from '@wix/yoshi-flow-editor';

import { IGroupMembersEvent } from 'api/events/types';

import * as events from 'store/events';
import type { IRootStore } from 'store/index';

export function EventsVM(params: ControllerParams, store: IRootStore) {
  return {
    events$: {
      fetch,
      create,
    },
  };

  function fetch(groupId: string) {
    return store.dispatch(events.thunks.fetch(groupId));
  }

  function create(params: IGroupMembersEvent) {
    return store.dispatch(events.thunks.create(params));
  }
}

export type IEventsVM = ReturnType<typeof EventsVM>;
