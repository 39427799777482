import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type {
  CursorPaging,
  Query,
} from '@wix/ambassador-feed-v1-feed-item/types';
import type { RichContent } from '@wix/ricos';

import { ReactionMode } from 'api/feed/types';

import * as feed from 'store/feed';
import { ISearchParams } from 'store/feed/types';
import * as application from 'store/application';
import {
  selectCurrentUser,
  selectFeedPermissionsByGroup,
  selectPinnedItem,
} from 'store/selectors';
import type { IRootStore } from 'store/types';

import type { ICommentsVM } from './comments.vm';

export function FeedVM(
  params: ControllerParams,
  comments: ICommentsVM,
  store: IRootStore,
) {
  return {
    feed$: {
      search,
      remove,
      fetch,
      create,
      update,
      react,
      unreact,
      pin,
      unpin,
      subscribe,
      unsubscribe,
      filter,
      get,
      view,
    },
  };

  function view(groupId: string, feedItemId: string) {
    return store.dispatch(feed.thunks.view({ groupId, feedItemId }));
  }

  function search(params: ISearchParams) {
    return store.dispatch(feed.thunks.search(params));
  }

  async function create(
    groupId: string,
    content: RichContent | undefined,
    title: string | undefined,
    topics: string[],
    withComments?: boolean,
  ) {
    const feedItem = await store
      .dispatch(
        feed.thunks.create({
          groupId,
          content,
          title,
          topics,
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch([feedItem]);
    }

    return feedItem;
  }

  function update(
    groupId: string,
    feedItemId: string,
    content: RichContent | undefined,
    title: string | undefined,
    topics: string[],
  ) {
    store.dispatch(
      feed.thunks.update({
        groupId,
        feedItemId,
        content,
        title,
        topics,
      }),
    );
  }

  async function filter(
    groupId: string,
    query?: Query,
    withComments?: boolean,
  ) {
    const { data } = await store
      .dispatch(
        feed.thunks.fetch({
          groupId,
          query,
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }

  async function get(
    groupId: string,
    feedItemId: string,
    withComments?: boolean,
  ) {
    const feedItem = await store
      .dispatch(
        feed.thunks.get({
          groupId,
          feedItemId,
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch([feedItem]);
    }

    return feedItem;
  }

  async function fetch(
    groupId: string,
    paging: CursorPaging = {},
    withComments = false,
  ) {
    const { cursor, limit = 5 } = paging;

    const { data } = await store
      .dispatch(
        feed.thunks.fetch({
          groupId,
          cursor: {
            cursor,
            limit,
          },
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }

  function subscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  async function pin(groupId: string, feedItemId: string) {
    const pinnedItem = selectPinnedItem(store.getState());

    if (pinnedItem) {
      await unpin(groupId, pinnedItem.feedItemId as string);
    }

    return store.dispatch(
      feed.thunks.pin({
        feedItemId,
        groupId,
      }),
    );
  }

  function unpin(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unpin({
        feedItemId,
        groupId,
      }),
    );
  }

  function react(
    groupId: string,
    feedItemId: string,
    code: string,
    mode = ReactionMode.APPEND,
  ) {
    const user = selectCurrentUser(store.getState());

    return store.dispatch(
      feed.thunks.react({
        user,
        mode,
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(groupId: string, feedItemId: string, code: string) {
    const user = selectCurrentUser(store.getState());

    return store.dispatch(
      feed.thunks.unreact({
        user,
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function remove(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.remove({
        groupId,
        feedItemId,
      }),
    );
  }
}

export type IFeedVM = Omit<ReturnType<typeof FeedVM>, '$'>;
