import { dsl } from '@wix/yoshi-serverless/wrap';

export const query = dsl({
          functionName: 'query',
          fileName: 'api/events/events.api',
        });

export const getConnectedEvents = dsl({
          functionName: 'getConnectedEvents',
          fileName: 'api/events/events.api',
        });

export const create = dsl({
          functionName: 'create',
          fileName: 'api/events/events.api',
        });