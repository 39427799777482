import * as events from 'api/events';
import { IGroupMembersEvent } from 'api/events/types';

import * as application from 'store/application';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'events:fetch',
  async function (groupId: string, thunkAPI) {
    return thunkAPI.extra.httpClient
      .request(events.getConnectedEvents(groupId))
      .then((response) => response.data);
  },
);

export const create = createAsyncThunk(
  'events:create',
  async function (params: IGroupMembersEvent, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      events.create({
        event: params,
      }),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        i18nParams: { i18nKey: 'groups-web.toast.events.create' },
      }),
    );

    return data;
  },
  {
    formatError: (params) => ({
      i18nKey: 'groups-web.toast.error.events.create',
    }),
  },
);
