import type { RawParams, Transition } from '@wix/tpa-router';

import type { IGroup } from 'api/groups/types';
import type { IApplicationUser } from 'store/application/types';
import {
  selectGroupBySlug,
  selectHasAdminRole,
  selectIsJoinedGroupMember,
} from 'store/selectors';

import type { StateDeclarationFn } from 'controller/types';

export default (function (vm, store, params) {
  return {
    name: 'group',
    parent: 'social-groups',
    url: '/:slug?autoInviteId&invite&appSectionParams',
    params: {
      slug: {
        value: null,
        squash: true,
      },
      appSectionParams: {
        dynamic: true,
        type: 'json',
      },
      invite: {
        type: 'bool',
        dynamic: true,
      },
    },
    data: { sectionId: 'group' },
    resolve: [
      {
        token: 'user',
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'group',
        deps: ['$stateParams', '$transition$'],
        async resolveFn(params: RawParams, transition: Transition) {
          const { custom } = transition.options();

          if (custom?.retain) {
            return selectGroupBySlug(store.getState(), params.slug);
          }

          const data = await vm.group$
            .fetch(params.slug, params.autoInviteId)
            .unwrap();

          return data.group;
        },
      },
      {
        token: 'groupId',
        deps: ['group'],
        resolveFn(group: IGroup) {
          return group.id;
        },
      },
      {
        token: 'isAdmin',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectHasAdminRole(store.getState(), groupId);
        },
      },
      {
        token: 'ppApi',
        async resolveFn() {
          await vm._.pricingPlans.init();
        },
      },
      {
        token: 'isJoined',
        deps: ['groupId'],
        resolveFn(groupId: string) {
          return selectIsJoinedGroupMember(store.getState(), groupId);
        },
      },
    ],
  };
} as StateDeclarationFn);
